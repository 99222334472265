import { ga } from './analytics'

const sendEvent = ({
  category = 'Link',
  action = 'click',
  label = 'Link clicked'
}) => {
  ga('send', {
    hitType: 'event',
    eventCategory: category,
    eventAction: action,
    eventLabel: label
  })
}

export default sendEvent
