import React from 'react'
import { Meta } from 'react-head'
import { css } from 'linaria'

import Layout from '../components/Layout'
import Section from '../components/Section'
import SectionContent from '../components/Section/Content'

import { Lead, Subheading } from '../components/TextElements'
import rhythm from '../utils/rhythm'
import { colors } from '../utils/theme'
import SocialMedia from '../components/Footer/SocialMedia'
import GoogleMap from '../components/GoogleMap'

import { ReactComponent as SmallLogo } from '../assets/svg/logo-small-white.svg'
import { ReactComponent as LetterIcon } from '../assets/svg/icons/letter.svg'
import { ReactComponent as PhoneIcon } from '../assets/svg/icons/phone.svg'

import hexToRgb from '../utils/hexToRgb'
import breakpoints from '../utils/tokens/breakpoints'
import sendEvent from '../utils/sendEvent'

const emailsList = css`
  list-style: none;
  margin: 0;
  padding: 0;
  color: ${colors.text.lightGray};
  font-size: 1.125rem;

  li + li {
    margin-top: ${rhythm(1)};
  }
`

const addressBlock = css`
  font-style: normal;
  color: ${colors.text.darkGray};
  font-size: 1.125rem;

  span {
    color: ${colors.text.lightGray};
  }
`

const subheading = css`
  margin-bottom: ${rhythm(2 / 3)};
  color: ${colors.text.lightGray};
`

const socials = css`
  list-style: none;
  margin: ${rhythm(5 / 2)} 0 0;
  justify-content: flex-start;

  a {
    color: ${colors.text.darkGray};
  }

  li + li {
    margin-left: ${rhythm(2)};
  }

  svg {
    transform: scale(1.5);
  }
`

const linkClass = css`
  color: ${colors.accent.purple};
  text-decoration: none;
`

const letterIcon = css`
  position: relative;
  top: 0.25rem;
  margin-right: ${rhythm(1 / 2)};

  path {
    stroke: ${colors.text.lightGray};
  }
`

const ContactEmails = () => (
  <div>
    <ul className={emailsList}>
      <li>
        <Subheading tag="p" variant="default" className={subheading}>
          New business
        </Subheading>
        <LetterIcon className={letterIcon} />
        <a
          href="mailto:sales@bejamas.io"
          className={linkClass}
          onClick={() => sendEvent({ label: 'sales@bejamas.io' })}
        >
          sales@bejamas.io
        </a>
      </li>
      <li>
        <Subheading tag="p" variant="default" className={subheading}>
          Want to join us?
        </Subheading>
        <LetterIcon className={letterIcon} />
        <a
          href="mailto:hr@bejamas.io"
          className={linkClass}
          onClick={() => sendEvent({ label: 'hr@bejamas.io' })}
        >
          hr@bejamas.io
        </a>
      </li>
    </ul>
    <SocialMedia className={socials} />
  </div>
)

const Page = ({ location }) => (
  <Layout
    location={location}
    title="Let's Talk"
    description="Tell us about your project, get a quick estimate and a plan for making it live."
    image="https://bejamas-care.now.sh/**Let's%20Talk**.png?theme=light-v1&md=1&fontSize=100px&images=https%3A%2F%2Fassets-bejamas.now.sh%2Fbrand%2Fsvg%2FBejamas_logo_black.svg"
    showCtaInHeader={true}
    jsonLD={`
      {
        "@context": "http://schema.org",
        "@type": "Organization",
        "name": "Bejamas",
        "description": "JAMstack Developers for hire. A Web Dev shop focused on helping agencies and companies with serverless web, Static Site Generators & JAMstack tools.",
        "image": "https://bejamas.io/icons/icon-512x512.png",
        "logo": "https://bejamas.io/icons/icon-512x512.png",
        "url": "https://bejamas.io/",
        "telephone": "+48690442069",
        "sameAs": ["https://twitter.com/bejamas_io","https://www.linkedin.com/company/bejamas/","https://www.facebook.com/bejamas.io"],
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Szczytnicka 11",
          "addressLocality": "Wrocław",
          "postalCode": "50-382",
          "addressCountry": "Poland"
        }
      }
    `}
  >
    <Section>
      <SectionContent
        wide
        rowGap="0"
        className={css`
          ${{
            marginTop: 0,
            gridTemplateColumns: '100%',
            [`@media (min-width: ${breakpoints.sm})`]: {
              gridTemplateColumns: '65%'
            },
            [`@media (min-width: ${breakpoints.lg})`]: {
              gridTemplateColumns: '50%'
            }
          }}
        `}
      >
        <h1>Let&apos;s Talk</h1>
        <Lead>
          Tell us about your project, get a quick estimate and a plan for making
          it live.
        </Lead>
      </SectionContent>
      <SectionContent
        wide
        rowGap="2"
        className={css`
          ${{
            marginTop: rhythm(1),
            gridTemplateColumns: 'repeat(auto-fit, minmax(220px, 1fr))'
          }}
        `}
      >
        <ContactEmails />

        <address className={addressBlock}>
          <Subheading tag="p" variant="default" className={subheading}>
            Headquarters
          </Subheading>
          Bejamas Group Sp. z o.o.
          <br />
          Business Link Green2Day
          <br />
          Szczytnicka 11
          <br />
          50-382 Wroclaw
          <br />
          Poland
          <p
            className={css`
              ${{ marginTop: rhythm(1) }}
            `}
          >
            <PhoneIcon className={letterIcon} />
            <a
              href="tel:+48690442069"
              className={linkClass}
              onClick={() => sendEvent({ label: 'Phone +48 690 442 069' })}
            >
              +48 690 442 069
            </a>
          </p>
        </address>
        <GoogleMap />
      </SectionContent>
    </Section>
  </Layout>
)

export default Page
