import React, { useContext } from 'react'
import { css } from 'linaria'
import breakpoints from '../../utils/tokens/breakpoints'
import { colors } from '../../utils/theme'
import hexToRgb from '../../utils/hexToRgb'
import { ReactComponent as SmallLogo } from '../../assets/svg/logo-small-white.svg'
import ThemeContext from '../ThemeContext'

import GoogleMapReact from 'google-map-react'

import themeDark from './theme-dark'
import themeLight from './theme-light'
import cx from '../../utils/cx'

const markerClass = css`
  width: 2rem;
  height: 2rem;
  border: 2px solid ${colors.text.white};
  background: ${colors.accent.purple};
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0 rgba(${hexToRgb(colors.accent.purple)}, 0.4);
  animation: pulse 2s infinite;

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(${hexToRgb(colors.accent.purple)}, 0.4);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(${hexToRgb(colors.accent.purple)}, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(${hexToRgb(colors.accent.purple)}, 0);
    }
  }
`

const Marker = () => (
  <div className={markerClass}>
    <SmallLogo viewBox="0 0 317.758 283.971" width="12" height="12" />
  </div>
)

const mapContainer = css`
  height: 450px;
  width: 100%;
  background-size: cover;
  background-position: center;

  @media (max-width: ${breakpoints.lg}) {
    grid-column: 1 / -1;
  }

  > div > div > div {
    background-color: transparent !important;
  }

  body.light & {
    background-image: url(/img/map-bg-light.png);
  }

  body.dark & {
    background-image: url(/img/map-bg-dark.png);
  }
`

const GoogleMap = () => {
  const { mode } = useContext(ThemeContext)

  return (
    <div className={mapContainer}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: 'AIzaSyAlIUk66LdaJPf2_5jV4av8ri1LIfqrr7w'
        }}
        defaultCenter={{
          lat: 51.113354,
          lng: 17.052612
        }}
        defaultZoom={13}
        options={{
          styles: mode === 'dark' ? themeDark : themeLight
        }}
      >
        <Marker lat={51.113354} lng={17.052612} />
      </GoogleMapReact>
    </div>
  )
}

export default GoogleMap
